import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Heading from '../components/Heading'
import OrderForm from '../components/OrderForm'
import OrderConfirm from '../components/OrderConfirm'
import OrderComplete from '../components/OrderComplete'
import queryString from 'query-string'

export default class BlogIndexPage extends React.Component {
  constructor(props) {
    super(props)

    const queries = queryString.parse(this.props.location.search)

    this.inputs = [
      {
        name: 'product',
        label: '商品名',
        type: 'text',
        placeholder: 'クロワッサン6個入り',
        value: queries.product,
      },
      {
        name: 'name',
        label: 'お名前',
        type: 'text',
        placeholder: '田中 太郎',
      },
      {
        name: 'kana',
        label: 'フリガナ',
        type: 'text',
        placeholder: 'タナカ タロウ',
      },
      {
        name: 'tel',
        label: '電話番号',
        type: 'tel',
        placeholder: '080-0000-0000',
      },
      {
        name: 'email',
        label: 'メールアドレス',
        type: 'email',
        placeholder: 'mail@example.com',
      },
      {
        name: 'postalcode',
        label: '郵便番号',
        type: 'text',
        placeholder: '111-1111',
      },
      {
        name: 'address',
        label: '住所',
        type: 'text',
        placeholder: '東京都世田谷区太子堂 2-38-4',
      },
    ]
    const keys = this.inputs.reduce((prev, current) => {
      prev[current.name] = current.value ? current.value : ''
      return prev
    }, {})
    this.state = Object.assign(
      {
        confirm: false,
        complete: false,
        errors: [],
      },
      keys
    )
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value.trim(),
    })
  }

  validate = () => {
    const keys = this.inputs.map(input => input.name)
    const errors = keys
      .filter(key => this.state[key] === '')
      .map(key => {
        const item = this.inputs.find(input => input.name === key)
        return `${item.label}が入力されていません。`
      })
    if (errors.length === 0) {
      this.setState({ confirm: true, errors: [] })
    } else {
      this.setState({ errors })
    }
  }

  confirm = event => {
    event.preventDefault()
    this.validate()
  }

  sendEmail = () => {
    const url = '/.netlify/functions/sendmail'
    const options = {
      method: 'POST',
      headers: {
        Accept: 'applicatiton/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state),
    }

    return fetch(url, options)
  }

  completeOrder = () => {
    this.sendEmail()
      .then(res => {
        console.log(res)
        this.setState({ confirm: false, complete: true })
      })
      .catch(e => {
        console.log(e)
      })
  }

  handleCancel = () => {
    this.setState({ confirm: false })
  }

  render() {
    const orderForm =
      !this.state.confirm && !this.state.complete ? (
        <OrderForm
          inputs={this.inputs}
          handleInputChange={this.handleInputChange}
          handleSubmit={this.confirm}
          {...this.state}
        ></OrderForm>
      ) : (
        ''
      )
    const checkForm = this.state.confirm ? (
      <OrderConfirm
        {...this.state}
        inputs={this.inputs}
        handleSubmit={this.completeOrder}
        handleCancel={this.handleCancel}
      />
    ) : (
      ''
    )
    const complete = this.state.complete ? <OrderComplete /> : ''
    const errors =
      this.state.errors.length > 0 ? (
        <div style={{ marginBottom: '32px' }}>
          {this.state.errors.map(error => {
            return (
              <div className="has-text-danger" key={error}>
                {error}
              </div>
            )
          })}
        </div>
      ) : (
        ''
      )

    return (
      <Layout>
        <Helmet titleTemplate="ご注文 | %s" />
        <Heading heading="ご注文" />
        <section className="section">
          <div className="container">
            {errors}
            {orderForm}
            {checkForm}
            {complete}
          </div>
        </section>
      </Layout>
    )
  }
}
