import React from 'react'
import { Link } from 'gatsby'

const OrderComplete = class extends React.Component {
  render() {
    return (
      <div className="content">
        <h3>注文が完了しました。</h3>
        <div>
          以降のやり取りはEメールで行われます。メールでのご案内をお待ち下さい。
        </div>
        <div className="field">
          <div className="control column">
            <Link className="button is-dark" to="/shop">
              商品一覧に戻る
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderComplete
