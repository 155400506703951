import React from 'react'

const OrderConfirm = class extends React.Component {
  handleSubmit = event => {
    this.props.handleSubmit(event)
  }

  handleCancel = event => {
    this.props.handleCancel(event)
  }

  render() {
    return (
      <div className="content">
        <div style={{ marginBottom: '32px' }}>
          以下の内容でメールを送信します。
        </div>
        <table className="table">
          <tbody>
            {this.props.inputs.map(item => {
              return (
                <tr key={item.name}>
                  <td>{item.label}</td>
                  <td>{this.props[item.name]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="field">
          <div className="control column">
            <button className="button is-dark" onClick={this.handleSubmit}>
              注文する
            </button>
            <button
              className="button is-light"
              onClick={this.handleCancel}
              style={{ marginLeft: '12px' }}
            >
              修正する
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderConfirm
