import React from 'react'

const OrderForm = class extends React.Component {
  handleInputChange = event => {
    this.props.handleInputChange(event)
  }

  handleSubmit = event => {
    this.props.handleSubmit(event)
  }

  render() {
    return (
      <div className="content">
        {this.props.inputs.map(item => {
          return (
            <div className="field" key={item.name}>
              <label className="label">{item.label}</label>
              <div className="control">
                <input
                  className="input"
                  type={item.type}
                  placeholder={
                    item.placeholder ? `例）` + item.placeholder : ''
                  }
                  name={item.name}
                  value={this.props[item.name]}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          )
        })}
        <div className="field">
          <div className="control column">
            <button className="button is-dark" onClick={this.handleSubmit}>
              入力内容を確認する
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderForm
